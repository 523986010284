import { createSlice } from '@reduxjs/toolkit';
import { initial } from 'lodash';
import axios from 'src/utils/terotechDataservice';

const initialValuesMachine = {
  machineType: 0,
  machineCode: '',
  machineDesc: '',
  machineSup: '',
  machineSAPCode: '',
  machineYear: '',
  machineDistC: '',
  machineDistL: '',
  machineCostBD: '',
  machineSpareN: '',
  machineClassCd: '',
  machineClassRea: '',
  machineClassPre: '',
  machinePrice: '',
  machineNoteGeneral: '',
  machineNoteSecurity: '',
  machineImage: '',
  machinePortata: '',
  machineStato: '',
  machineVitaRes: '',
  machineFabbrica: '',
  machineModello: '',
  machineFaldone: '',
  machinePostazioneMu: '',
  machineTargaFiat: '',
  machineMatricolaInv: ''
};

const initialState = {
  notifications: [],
  status: 'Status iniziale',
  machine: initialValuesMachine
};

const slice1 = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    }
  }
});
export const reducer1 = slice1.reducer;

const slice2 = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    getMachine(state, action) {
      state.status = 'getMachine';
      state.machine = {
        idMachine: action.payload.id,
        machineType: action.payload.idtipology,
        machineCode: action.payload.code,
        machineDesc: action.payload.description,
        machineSup: action.payload.idsupplier,
        machineSAPCode: action.payload.codeSap,
        machineYear: action.payload.yearSetup,
        machineDistC: action.payload.distCentralWarehouse || '0',
        machineDistL: action.payload.distLubrificantWarehouse || '0',
        machineCostBD: '',
        machineSpareN: '',
        machineClassCd: action.payload.classCd,
        machineClassRea: action.payload.classRea,
        machineClassPre: action.payload.classPro,
        machinePrice: action.payload.price,
        machineNoteGeneral: action.payload.noteGeneral,
        machineNoteSecurity: action.payload.noteSecurity,
        machineOperation: action.payload.idoperazione,
        machineImage: action.payload.fileImg,
        machinePortata: action.payload.portata,
        machineStato: action.payload.stato,
        machineVitaRes: action.payload.scadVitaResidua,
        machineFabbrica: action.payload.fabbrica,
        machineModello: action.payload.modello,
        machineFaldone: action.payload.faldone,
        machinePostazioneMu: action.payload.postazioneMu,
        machineTargaFiat: action.payload.targaFiat,
        machineMatricolaInv: action.payload.matricolaInv
      };
    },
    updateMachine(state, action) {
      console.log('State');
      console.log(state);
      console.log('Action');
      console.log(action);
      state.status = 'updateMachine';
      state.machine.idMachine = action.payload.id || state.machine.idMachine;
      state.machine.machineType =
        action.payload.idtipology || state.machine.machineType;
      state.machine.machineCode =
        action.payload.code || state.machine.machineCode;
      state.machine.machineDesc =
        action.payload.description || state.machine.machineDesc;
      state.machine.machineSup =
        action.payload.idsupplier || state.machine.machineSup;
      state.machine.machineSAPCode =
        action.payload.codeSap || state.machine.machineSAPCode;
      state.machine.machineYear =
        action.payload.yearSetup || state.machine.machineYear;
      state.machine.machineDistC =
        action.payload.distCentralWarehouse || state.machine.machineDistC;
      state.machine.machineDistL =
        action.payload.distLubrificantWarehouse || state.machine.machineDistL;
      state.machine.machineCostBD = '';
      state.machine.machineSpareN = '';
      state.machine.machineClassCd =
        action.payload.classCd || state.machine.machineClassCd;
      state.machine.machineClassRea =
        action.payload.classRea || state.machine.machineClassRea;
      state.machine.machineClassPre =
        action.payload.classPro || state.machine.machineClassPre;
      state.machine.machinePrice =
        action.payload.price || state.machine.machinePrice;
      state.machine.machineNoteGeneral =
        action.payload.noteGeneral || state.machine.machineNoteGeneral;
      state.machine.machineNoteSecurity =
        action.payload.noteSecurity || state.machine.machineNoteSecurity;
      state.machine.machineImage =
        action.payload.fileImg || state.machine.machineImage;
      state.machine.machinePortata =
        action.payload.portata || state.machine.machinePortata;
      state.machine.machineStato =
        action.payload.stato || state.machine.machineStato;
      state.machine.machineVitaRes =
        action.payload.scadVitaResidua || state.machine.machineVitaRes;
      state.machine.machineFabbrica =
        action.payload.fabbrica || state.machine.machineFabbrica;
      state.machine.machineModello =
        action.payload.modello || state.machine.machineModello;
      state.machine.machineFaldone =
        action.payload.faldone || state.machine.machineFaldone;
      state.machine.machinePostazioneMu =
        action.payload.postazioneMu || state.machine.machinePostazioneMu;
      state.machine.machineTargaFiat =
        action.payload.targaFiat || state.machine.machineTargaFiat;
      state.machine.machineMatricolaInv =
        action.payload.matricolaInv || state.machine.machineMatricolaInv;
    },
    putMachine(state, action) {
      state.status = 'putMachine';
    },
    resetMachine(state, action) {
      state.machine = initialValuesMachine;
      console.log('Reset stato macchina', state.machine);
    }
  }
});
export const reducer2 = slice2.reducer;

export const getNotifications = () => async dispatch => {
  const response = await axios.get('/api/notifications');
  dispatch(slice1.actions.getNotifications(response.data));
};

export const getMachine = id => async dispatch => {
  const response = await axios.get('/machine/items/' + id);
  console.log(response.data);
  dispatch(slice2.actions.getMachine(response.data));
};

export const updateMachine = update => async dispatch => {
  dispatch(slice2.actions.updateMachine(update));
};

export const resetMachine = () => async dispatch => {
  dispatch(slice2.actions.resetMachine());
};

export const putMachine = (
  id,
  machinecode,
  machinecodesap,
  machinedesc,
  machineyear,
  machinetype,
  machinesup,
  machinedistc,
  machinedistl,
  machinelassCD,
  machineclassRea,
  machineprice,
  machinenoteGen,
  machinenoteSafety,
  machineop,
  machineimage,
  machinePortata,
  machineStato,
  machineVitaRes,
  machineFabbrica,
  machineModello,
  machineFaldone,
  machinePostazioneMu,
  machineTargaFiat,
  machineMatricolaInv
) => async dispatch => {
  //let axios1 = require('axios');

  const idMac = id;

  // axios1.defaults.baseURL = 'http://myurl';
  // axios.defaults.crossDomain = true;
  // axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
  // axios.defaults.headers.put["accept"] = "*/*";
  // axios.defaults.headers.put["Content-Type"] = "application/json-patch+json";
  const response = await axios.put('/machine/items', {
    id: id,
    code: machinecode,
    codeSap: machinecodesap,
    description: machinedesc,
    yearSetup: machineyear,
    idTipology: parseInt(machinetype),
    idSupplier: parseInt(machinesup),
    distWareCentral: machinedistc,
    distWareLubrif: machinedistl,
    classCD: machinelassCD,
    classREA: machineclassRea,
    classPRE: '',
    price: machineprice,
    noteGeneral: machinenoteGen,
    noteSecurity: machinenoteSafety,
    idoperazione: machineop,
    fileImg: machineimage,
    portata: machinePortata,
    stato: machineStato,
    scadVitaResidua: machineVitaRes,
    fabbrica: machineFabbrica,
    modello: machineModello,
    faldone: machineFaldone,
    postazioneMu: machinePostazioneMu,
    targaFiat: machineTargaFiat,
    matricolaInv: machineMatricolaInv,
    flgTwin: 0
  }); // "/api" + "/v1

  //  "{\"id\":"+ idMac+ ",\"code\":\""+code+"\",\"codeSap\":\"" + sap +"\",\"description\":\"MASCHERA AVPE\",\"yearSetup\":" + year + ",\"idTipology\":"+idMachineType+",\"idSupplier\":"+idSupplier+",\"distWareCentral\":" + distc + ",\"distWareLubrif\":" + distl + ",\"flgTwin\":0,\"fileImg\":\"plt00012/a1b89be1-cd13-4564-95ed-5fc0d5cff6a5.png\",\"classCD\":\""+classCD +"\",\"classREA\":\""+classRea +"\",\"classPRE\":\"C\",\"price\":\""+price+"\",\"noteGeneral\":\""+noteGen +"\",\"noteSecurity\":\""+noteSafety +"\"}");

  dispatch(slice2.actions.putMachine(response.data));
};

export const putImage = (id, fileImg) => async dispatch => {
  let axios1 = require('axios');
  const idMachine = id;
  // axios1.defaults.baseURL = 'http://myurl';
  axios.defaults.crossDomain = true;
  axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.put['accept'] = '*/*';
  axios.defaults.headers.put['Content-Type'] = 'multipart/form-data';
  const response = await axios1.post(
    '/machine/image?id=' + idMachine,
    'file=@' + fileImg
  );
  dispatch(slice2.actions.putImage(response.data));
};

export { slice1, slice2 };
