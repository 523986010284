import axios from 'src/utils/terotechDataservice';

function get() {
  const options = {
    headers: { 'Content-Type': 'application/json' }
  };
  return axios.get('/user/settings', options);
}

function put(data) {
  const options = {
    headers: { 'Content-Type': 'application/json' }
  };
  return axios.put('/user/settings', data, options);
}

export { get, put };
